import React, {Component, Fragment} from 'react';
import {Button, Container, Nav, Navbar} from "react-bootstrap";
import new_logo from "../Assets/img/new_logo.svg"
class TopNavBar extends Component {
    render() {
        return (
            <Fragment>
                <Navbar bg="light" className="sticky-top" expand="lg">
                    <Container fluid>
                         <img src={new_logo} className="nav-logo" alt="nav-logo"/>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav className="me-auto my-2 my-lg-0">
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Fragment>
        );
    }
}

export default TopNavBar;